import React from 'react';
import { Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useLocation } from 'react-router-dom';

import ZayavkiRemontModule from "./Components/zayavki/ZayavkiModule";
import CardRem from './Components/zayavki/card/card2';
import ZayavkiSSaytaModule from "./Components/Page/zayavkissite/ZayavkiSSite";
import Profile from './Components/Page/ProfilePage/ProfilePage';
import Users from './Components/Page/ProfilePage/users';
import Card from './Components/Page/zayavkissite/card/card2';
import ServiceUpdatesBlog from './Components/Page/obnovlenia/Obnovlenia';
import Cartr from './Components/Page/cartr/proisv';
import CartidgesList from './Components/Page/cartr/CartidgesList';
import KurierPage from './Components/Page/kurierPage/KurierPage';
import OrganisationListPAge from './Components/Page/organizations/OrganizationsModule';
import OrganizationDetails from "./Components/Page/organizations/OrganizationDetails";
import Home from "./Components/Page/home/home";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/users" element={<Users />} />
        <Route path="/organisations" element={<OrganisationListPAge />} />
        <Route path="/organisations/cardorg/:id" element={<OrganizationDetails />} />
        <Route path="/kurierpage" element={<KurierPage />} />
        <Route path="/zayavkiremont" element={<ZayavkiRemontModule />} />
        <Route path="/zayavkiremont/cardrem/:id" element={<CardRem />} />
        <Route path="/zayavkissayta" element={<ZayavkiSSaytaModule />} />
        <Route path="/zayavkissayta/card/:id" element={<Card />} />
        <Route path="/serviceupdatesblog" element={<ServiceUpdatesBlog />} />
        <Route path="/cart" element={<Cartr />} />
        <Route path="/cart/:brandId" element={<CartidgesList />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;