import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faWrench, faQuestionCircle, faUsers, faBuilding, faTags, faSync, faUser , faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Home = ({ toggleSidebar }) => {
    const navigate = useNavigate();
    const { store } = useContext(Context);
    const role = store.user.role;
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

    useEffect(() => {
        if (isUserMenuOpen) {
            setIsUserMenuOpen(false);
        }
    }, [store.user.id]);

    const handleLogout = () => {
        store.logout(); // Предполагается, что в вашем store есть метод logout 
        navigate('/'); // Перенаправление на страницу входа
    };

    const menuItems = {
        dev: [
            { label: 'Выезд курьера/инженера', path: 'kurierpage', icon: faTruck, description: 'Организуйте выезд специалиста.', group: 'Услуги' },
            { label: 'Ремонты техники', path: 'zayavkiremont', icon: faWrench, description: 'Управление заявками на ремонт.', group: 'Услуги' },
            { label: 'Вопросы с сайта', path: 'zayavkissayta', icon: faQuestionCircle, description: 'Ответы на вопросы клиентов.', group: 'Услуги' },
            { label: 'Пользователи', path: 'users', icon: faUsers, description: 'Управление пользователями системы.', group: 'Управление' },
            { label: 'Организации', path: 'organisations', icon: faBuilding, description: 'Список организаций.', group: 'Управление' },
            { label: 'Цены на работы по картриджам', path: 'cart', icon: faTags, description: 'Просмотр цен на услуги.', group: 'Управление' },
            { label: 'Профиль', path: 'profile', icon: faUser , description: 'Управление Вашим профилем.', group: 'Управление' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog', icon: faSync, description: 'Блог обновлений сервиса.', group: 'Дополнительно' },
            { label: 'Выход', path: '', icon: faSignOutAlt, description: 'Выйти из системы.', group: 'Управление', action: handleLogout },
        ],
        manager: [
            { label: 'Выезд курьера/инженера', path: 'kurierpage', icon: faTruck, description: 'Организуйте выезд специалиста.', group: 'Услуги' },
            { label: 'Ремонты техники', path: 'zayavkiremont', icon: faWrench, description: 'Управление заявками на ремонт.', group: 'Услуги' },
            { label: 'Вопросы с сайта', path: 'zayavkissayta', icon: faQuestionCircle, description: 'Ответы на вопросы клиентов.', group: 'Услуги' },
            { label: 'Пользователи', path: 'users', icon: faUsers, description: 'Управление пользователями системы.', group: 'Управление' },
            { label: 'Организации', path: 'organisations', icon: faBuilding, description: 'Список организаций.', group: 'Управление' },
            { label: 'Профиль', path: 'profile', icon: faUser , description: 'Управление Вашим профилем.', group: 'Управление' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog', icon: faSync, description: 'Блог обновлений сервиса.', group: 'Дополнительно' },
            { label: 'Выход', path: '', icon: faSignOutAlt, description: 'Выйти из системы.', group: 'Управление', action: handleLogout },
        ],
        orgSotr: [
            { label: 'Выезд курьера/инженера', path: 'kurierpage', icon: faTruck, description: 'Организуйте выезд специалиста.', group: 'Услуги' },
            { label: 'Ремонты техники', path: 'zayavkiremont', icon: faWrench, description: 'Управление заявками на ремонт.', group: 'Услуги' },
            { label: 'Профиль', path: 'profile', icon: faUser , description: 'Управление Вашим профилем.', group: 'Управление' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog', icon: faSync, description: 'Блог обновлений сервиса.', group: 'Дополнительно' },
            { label: 'Выход', path: '', icon: faSignOutAlt, description: 'Выйти из системы.', group: 'Управление', action: handleLogout },
        ],
        orgAdmin: [
            { label: 'Выезд курьера/инженера', path: 'kurierpage', icon: faTruck, description: 'Организуйте выезд специалиста.', group: 'Услуги' },
            { label: 'Ремонты техники', path: 'zayavkiremont', icon: faWrench, description: 'Управление заявками на ремонт.', group: 'Услуги' },
            { label: 'Организация', path: `organisations/cardorg/${store.user.orgID}`, icon: faBuilding, description: 'Информация об организации.', group: 'Управление' },
            { label: 'Профиль', path: 'profile', icon: faUser , description: 'Управление Вашим профилем.', group: 'Управление' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog', icon: faSync, description: 'Блог обновлений сервиса.', group: 'Дополнительно' },
            { label: 'Выход', path: '', icon: faSignOutAlt, description: 'Выйти из системы.', group: 'Управление', action: handleLogout },
        ],
        fis: [
            { label: 'Ремонты техники', path: 'zayavkiremont', icon: faWrench, description: 'Управление заявками на ремонт.', group: 'Услуги' },
            { label: 'Профиль', path: 'profile', icon: faUser , description: 'Управление Вашим профилем.', group: 'Управление' },
            { label: 'Обновления сервиса', path: 'serviceupdatesblog', icon: faSync, description: 'Блог обновлений сервиса.', group: 'Дополнительно' },
            { label: 'Выход', path: '', icon: faSignOutAlt, description: 'Выйти из системы.', group: 'Управление', action: handleLogout },
        ],
    };

    const menuItemList = menuItems[role];

    if (!menuItemList) {
        return (
            <div className="flex justify-center items-center h-full text-gray-700">
                Нет доступных пунктов меню для роли {role}
            </div>
        );
    }

    const groupedItems = menuItemList.reduce((acc, item) => {
        acc[item.group] = acc[item.group] || [];
        acc[item.group].push(item);
        return acc;
    }, {});

    return (
        <div className="h-full flex-1 flex flex-col items-center text-gray-900 p-4 bg-gray-100">
            {Object.entries(groupedItems).map(([group, items]) => (
                <div key={group} className="w-full mb-6">
                    <h2 className="text-2xl font-bold text-gray-700 mb-6">{group}</h2>
                    <div className="flex flex-wrap flex-1 gap-6">
                        {items.map((item, index) => (
                            <div key={index}
                                onClick={() => item.action ? item.action() : navigate(item.path)}
                                className="bg-white p-6 rounded-xl shadow-md cursor-pointer hover:bg-slate-100 transition-all transform hover:shadow-lg hover:scale-105 flex-grow md:w-full lg:max-w-64"
                            >
                                <div className="flex items-center justify-center mb-4">
                                    <FontAwesomeIcon icon={item.icon} className="text-4xl text-blue-500" />
                                </div>
                                <div className="text-center">
                                    <span className="text-xl font-semibold text-gray-800">{item.label}</span>
                                    <p className="text-sm text-gray-500 mt-2">{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default observer(Home);
