import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion, LayoutGroup } from 'framer-motion';
import Modal from 'react-modal';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';

const OrganizationDetails = ({ org }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('employees');
    const [searchQuery, setSearchQuery] = useState('');
    const [sotrudniki, setSotrudniki] = useState([]); // Для хранения списка сотрудников
    const [filials, setFilials] = useState([]); // Для хранения списка сотрудников
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showAddEmployee, setShowAddEmployee] = useState(false); // Для управления видимостью формы добавления
    const [userID, setUserID] = useState([]); // Список пользователей для добавления сотрудника
    const [selectedUser, setSelectedUser] = useState(null); // Выбранный пользователь
    const [position, setPosition] = useState(''); // Должность сотрудника
    const [employeeRole, setEmployeeRole] = useState('');
    const [selectedFilialID, setSelectedFilialID] = useState('');
    const { store } = useContext(Context);
    const [organization, setOrganization] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddFilial, setShowAddFilial] = useState(false);

    const roles = ['orgAdmin', 'orgSotr'];

    useEffect(() => {
        console.log(store);
        const fetchZapSSiteById = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/organizations/organizationsById/${id}`);
                setOrganization(response.data);

                if (activeTab === 'branches') { // Если активная вкладка - "Сотрудники"
                    axios.get(`${API_URL}/organizations/filials`, { params: { id } }) // Выполните GET-запрос к API
                        .then(response => {
                            setFilials(response.data); // Сохраните полученные данные в состоянии
                        })
                        .catch(error => {
                            console.error('There was an error!', error);
                        });
                }
                if (activeTab === 'employees') { // Если активная вкладка - "Сотрудники"
                    axios.get(`${API_URL}/organizations/sotrudniki`, { params: { id } }) // Выполните GET-запрос к API
                        .then(response => {
                            setSotrudniki(response.data); // Сохраните полученные данные в состоянии
                        })
                        .catch(error => {
                            console.error('There was an error!', error);
                        });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchZapSSiteById();

    }, [showAddEmployee, activeTab, id]); // Запустите этот эффект при изменении activeTab или id

    const handleScheduleChange = (e) => {
        const { name, value } = e.target;
        const [day, timePoint] = name.split("-");
    };

    const [filialName, setFilialName] = useState('');
    const [filialAddress, setFilialAddress] = useState('');
    const [filialPhone, setFilialPhone] = useState('');

    const addFilial = () => {
        axios.post(`${API_URL}/organizations/addfilial`, {
            nameFilial: filialName,
            addresFilial: filialAddress,
            phoneFilial: filialPhone,
            orgID: id
        })
            .then(response => {
                // Обновляем список филиалов
                setFilials([...filials, response.data]);
                // Закрыть форму добавления
                setShowAddFilial(false);
                // Сбросить данные о новом филиале
                setFilialName('');
                setFilialAddress('');
                setFilialPhone('');
            })
            .catch(error => {
                console.error('Ошибка при добавлении филиала', error);
            });
    };

    const addFilialModal = (
        <Modal
            overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
            className="bg-white p-6 rounded-lg shadow-md w-1/2 max-w-md"
            isOpen={showAddFilial}
        >
            <div className="modal-content">
                <h2 className="text-lg font-bold mb-4">Добавить филиал</h2>
                <form>
                    <div className="flex flex-col mb-4">
                        <label className="text-sm text-gray-600 mb-2" htmlFor="filial-name">Название филиала</label>
                        <input
                            type="text"
                            id="filial-name"
                            placeholder="Название филиала"
                            value={filialName}
                            onChange={(e) => setFilialName(e.target.value)}
                            className="w-full p-2 pl-10 text-sm text-gray-600 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="text-sm text-gray-600 mb-2" htmlFor="filial-address">Адрес филиала</label>
                        <input
                            type="text"
                            id="filial-address"
                            placeholder="Адрес филиала"
                            value={filialAddress}
                            onChange={(e) => setFilialAddress(e.target.value)}
                            className="w-full p-2 pl-10 text-sm text-gray-600 border border-gray-300 rounded"
                        />
                    </div>

                    <div className="flex flex-col mb-4">
                        <label className="text-sm text-gray-600 mb-2" htmlFor="filial-phone">Телефон филиала</label>
                        <input
                            type="text"
                            id="filial-phone"
                            placeholder="Телефон филиала"
                            value={filialPhone}
                            onChange={(e) => setFilialPhone(e.target.value)}
                            className="w-full p-2 pl-10 text-sm text-gray-600 border border-gray-300 rounded"
                        />
                    </div>
                    <div className='flex justify-between gap-2'>
                        <button onClick={addFilial} className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded">
                            Добавить филиал
                        </button>
                        <button onClick={() => setShowAddFilial(false)} className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded">
                            Отменить
                        </button>
                    </div>
                </form>
            </div>
        </Modal>

    );

    // Функция для добавления нового сотрудника
    // Функция для добавления нового сотрудника
    const addEmployee = async () => {
        try {
            const response = await axios.post(`${API_URL}/organizations/sotrudnikadd`, {
                userID: userID,
                dolgnost: position,
                orgID: id,
                filialID: selectedFilialID,
                sotrudnikRole: employeeRole
            });

            axios.get(`${API_URL}/organizations/sotrudniki`, { params: { id } }) // Выполните GET-запрос к API
                        .then(response => {
                            setSotrudniki(response.data); // Сохраните полученные данные в состоянии
                        })
                        .catch(error => {
                            console.error('There was an error!', error);
                        });
            // Закрыть форму добавления
            setModalIsOpen(false);
            // Сбросить выбранного пользователя и должность
            setSelectedUser(null);
            setPosition('');
        } catch (error) {
            console.error('Ошибка при добавлении сотрудника', error);
        }
    };

    const deleteEmployee = (sotrudnikId) => {
        axios.delete(`${API_URL}/organizations/sotrudnikdelete/${sotrudnikId}`)
            .then(() => {
                setSotrudniki(sotrudniki.filter(sotrudnik => sotrudnik._id !== sotrudnikId));
            })
            .catch(error => {
                console.error('Ошибка при удалении сотрудника', error);
            });
    };

    // JSX код для добавления сотрудника
    const addEmployeeModal = (
        <Modal
            overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
            className="bg-white p-6 rounded-lg shadow-md w-1/2 max-w-md"
            isOpen={modalIsOpen}>
            <div className="modal-content">
                <button className="close" onClick={() => setModalIsOpen(false)}>X</button>
                <div>
                    Телефон пользователя:
                </div>
                <input
                    type="text"
                    placeholder="Телефон пользователя"
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                />

                <input
                    type="text"
                    placeholder="Должность"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                />
                {/* Поле для выбора роли сотрудника */}
                <select
                    value={employeeRole}
                    onChange={(e) => setEmployeeRole(e.target.value)}
                >
                    <option value="">Выбрать роль</option>
                    {roles.map((role) => (
                        <option key={role} value={role}>{role}</option>
                    ))}
                </select>

                {/* Поле для выбора филиала */}
                <select
                    value={selectedFilialID || ''}
                    onChange={(e) => setSelectedFilialID(e.target.value)}
                >
                    <option value="">Выбрать филиал</option>
                    {filials.map((filial) => (
                        <option key={filial._id} value={filial._id}>
                            {filial.nameFilial}
                        </option>
                    ))}
                </select>
                {/* Кнопка добавления сотрудника */}
                <button onClick={addEmployee}>Добавить сотрудника</button>
            </div>
        </Modal>
    );

    // Фильтрация организаций по категории и поиск
    const filteredSotrudniki = sotrudniki.filter(sotrudnik => {
        const isSearchMatch = sotrudnik.userID.familia.toLowerCase().includes(searchQuery.toLowerCase()) ||
            sotrudnik.userID.imya.toLowerCase().includes(searchQuery.toLowerCase()) ||
            sotrudnik.userID.otchestvo.toLowerCase().includes(searchQuery.toLowerCase());
        return isSearchMatch;
    });
    // Фильтрация организаций по категории и поиск
    const filteredFilials = filials.filter(filial => {
        const isSearchMatch = filial.nameFilial.toLowerCase().includes(searchQuery.toLowerCase());
        return isSearchMatch;
    });

    // Функция для переключения вкладок
    const switchTab = (tabName) => {
        setActiveTab(tabName);
    }

    // Анимация для страницы
    const pageTransition = {
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: '-100vw'
        }
    };

    // Обработчик изменения запроса поиска
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Контент для разных вкладок
    const tabContent = {
        employees: (
            <div>
                <ul>
                    {filteredSotrudniki.map((sotrudnik) => (
                        <li
                            key={sotrudnik._id}
                            className="sotr-container bg-white shadow-md rounded-lg overflow-hidden w-full mb-4"
                        >
                            <div className="p-4 flex justify-between items-center">
                                <div className="flex-grow">
                                    <div className="sotheader mb-2">
                                        <h3 className="text-lg font-bold text-gray-800">{sotrudnik.userID.familia} {sotrudnik.userID.imya} {sotrudnik.userID.otchestvo}</h3>
                                    </div>
                                    <div className="blocInfo text-sm flex justify-between">
                                        <div className="infoBlock">
                                            <p className="contact-text text-gray-600">Должность: {sotrudnik.dolgnost}</p>
                                            <p className="contact-text text-gray-600">Роль:
                                                {sotrudnik.userID.role === 'orgAdmin' ? ' Администратор' :
                                                    sotrudnik.userID.role === 'orgSotr' ? ' Пользователь' :
                                                        ' Не выбрано'}</p>
                                        </div>
                                        <div className="infoBlock">
                                            <p className="contact-text text-gray-600">Телефон: {sotrudnik.userID.phone}</p>
                                            <p className="contact-text text-gray-600">E-mail: {sotrudnik.userID.email}</p>
                                        </div>
                                        <div className="infoBlock">
                                            {sotrudnik.filialID ? (
                                                <div className="infoBlock">
                                                    <p className="contact-text text-gray-600">Филиал: {sotrudnik.filialID.nameFilial}</p>
                                                    <p className="contact-text text-gray-600">Адрес: {sotrudnik.filialID.addresFilial}</p>
                                                </div>
                                            ) : (
                                                <p className="contact-text text-gray-600">Не закреплен за филиалом</p>
                                            )}
                                        </div>
                                        <button onClick={() => deleteEmployee(sotrudnik._id)}
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
                    onClick={() => setModalIsOpen(!modalIsOpen)}
                >
                    Добавить сотрудника
                </button>
            </div>

        ),
        branches: (
            <div>
                <ul className="list-none mb-0">
                    {filteredFilials.map(filial => (
                        <li key={filial._id} className="flex flex-col mb-4 p-4 bg-white rounded-lg shadow-md transition duration-200 ease-in-out hover:scale-105">
                            <div className="flex justify-between mb-2">
                                <h3 className="text-lg font-bold">{filial.nameFilial}</h3>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-1/2 pr-4">
                                    <h3 className="text-sm font-bold mb-1">Контакты</h3>
                                    <p className="text-sm">{filial.phoneFilial}</p>
                                    <p className="text-sm">{filial.addresFilial}</p>
                                </div>
                                <div className="w-1/2 pl-4">
                                    {/* добавить информацию о филиале */}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <button className="bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out ml-auto" onClick={() => setShowAddFilial(true)}>
                    Добавить филиал
                </button>
            </div>
        )
        // Добавьте другие вкладки, если необходимо
    };

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={pageTransition}
            transition={{ type: 'tween', ease: 'anticipate', duration: 0.5 }}
            className="max-w-full w-full mx-auto p-5 rounded-lg bg-white shadow-md"
        >
            <div className="flex items-center gap-2 mb-2 bg-gray-800 p-2 rounded-lg">
                <button onClick={() => navigate(-1)} className="py-2 px-4 bg-transparent border-none cursor-pointer rounded-lg hover:bg-gray-700 transition duration-200 ease-in-out text-white shadow-md">
                    ← Назад
                </button>
                <div className="flex items-center relative text-lg text-white p-2 rounded-lg">
                    {organization.nameOrg}
                </div>
            </div>

            <div className="flex flex-col justify-between gap-2 flex-wrap">
                <div className='flex flex-row p-2 justify-between'>
                    <div className="mt-2 text-sm text-gray-700">
                        <div>ИНН: {organization.innOrg}</div>
                        <div>Телефон: {organization.phoneOrg}</div>
                        <div>Электронная почта: {organization.emailOrg}</div>
                    </div>

                    <div className="mt-2 text-sm flex flex-row text-gray-700 gap-2">
                        <div>
                            Адрес:
                        </div>
                        <div className="flex flex-wrap">
                            {organization.addressOrg}
                        </div>
                    </div>
                </div>

            </div>
            <div className="mt-4">
                <LayoutGroup>
                    <motion.ul className="flex items-center justify-between p-2 rounded-lg bg-gray-800 text-white">
                        <button
                            className={`py-2 px-4 bg-transparent border-none cursor-pointer rounded-lg hover:bg-gray-700 transition duration-200 ease-in-out text-white shadow-md ${activeTab === 'employees' ? 'bg-white bg-opacity-20' : ''}`}
                            onClick={() => switchTab('employees')}
                        >
                            Сотрудники
                        </button>
                        <button
                            className={`py-2 px-4 bg-transparent border-none cursor-pointer rounded-lg hover:bg-gray-700 transition duration-200 ease-in-out text-white shadow-md ${activeTab === 'branches' ? 'bg-white bg-opacity-20' : ''}`}
                            onClick={() => switchTab('branches')}
                        >
                            Филиалы
                        </button>
                        <div className="flex items-center gap-2">
                            <input
                                type="text"
                                placeholder="Поиск..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="border border-gray-300 p-2 rounded-lg text-gray-900"
                            />
                        </div>
                    </motion.ul>
                    <motion.div className="p-4 bg-gray-100 rounded-lg">
                        {tabContent[activeTab]}
                    </motion.div>
                </LayoutGroup>
            </div>

            {addEmployeeModal}
            {addFilialModal}
        </motion.div>
    );
};

export default observer(OrganizationDetails);