import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import './Components/alerts/CenteredVerticalForm/index';
import { AlertingService } from './Components/alerts/Alert/alerting_service';
import { CenteredVerticalForm } from './Components/alerts/CenteredVerticalForm/centered_vertical_form';

// Components
import Navigation from './Components/Navigation/Navigation';
import AnimatedRoutes from './AnimatedRoutes';
import Header from './Components/Header/Header';

const DashboardContent = () => {
    const pageMotion = {
        initial: { opacity: 0, x: 50, maxWidth: '90%' },
        animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
        exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsSidebarOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuItemClick = () => {
        if (window.innerWidth < 768) {
            setIsSidebarOpen(false);
        }
    };

    // Определяем, нужно ли скрывать боковую панель
    const shouldHideSidebar = location.pathname === '/'; // Предполагаем, что '/' это начальная страница

    return (
        <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
            <Header toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
            {!shouldHideSidebar && (
                <aside id="logo-sidebar"
                    className={`fixed top-0 left-0 z-40 w-full h-screen pt-20 transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} bg-gray-900 sm:w-64`}
                    aria-label="Sidebar"
                >
                    <Navigation onMenuItemClick={handleMenuItemClick} />
                </aside>
            )}
            <div className={`p-3 min-h-[calc(100vh-50px)] ${!shouldHideSidebar ? 'sm:ml-64' : ''}`}>
                <CenteredVerticalForm>
                    <AlertingService horizontal={'right'} vertical={'bottom'} />
                    <div className="flex relative w-full max-w-full bg-gray-50 p-2 border-2 border-dashed rounded-lg mt-14 min-h-[calc(100vh-150px)]">
                        <AnimatedRoutes />
                    </div>
                </CenteredVerticalForm>
            </div>
            <footer className='flex relative bottom-0 mt-auto z-50 w-full h-[50px] bg-slate-800 items-center justify-center content-center text-center text-slate-50'>
                <p>© 2024 Принт сервис | </p>
            </footer>
        </motion.div>
    );
};

const Dashboard = () => (
    <Router>
        <DashboardContent />
    </Router>
);

export default observer(Dashboard);
