import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { API_URL } from '../../../../../http';
import TextareaAutosize from 'react-textarea-autosize';
import { parse, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import './Obnovlenia.css';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../../index'; // путь к вашему контексту

const ServiceUpdatesBlog = () => {
  const [serviceUpdates, setServiceUpdates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updateDescription, setUpdateDescription] = useState('');
  const { store } = useContext(Context);
  const role = store.user.role;

  const todays = new Date();
  const dateString = new Date(todays.getFullYear(), todays.getMonth(), todays.getDate());
  const today = format(dateString, "d MMMM yyyy 'г.'", { locale: ru });

  const handleClick = () => {
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      await axios.post(`${API_URL}/obnovlenia/addobnovlenia`, { version: require('../../../../../../package.json').version, opisanie: updateDescription, data: today });
      setShowModal(false);
      setUpdateDescription('');
      fetchServiceUpdates();
    } catch (error) {
      console.error('Error saving service update:', error);
    }
  };

  const pageMotion = {
    initial: { opacity: 0, x: 50, display: 'flex', width: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', width: '100%', display: 'flex', flex: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, width: '90%', display: 'flex', transition: { duration: 0.3 } }
  };

  useEffect(() => {
    fetchServiceUpdates();
  }, []);

  const fetchServiceUpdates = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/obnovlenia/obnovleniaall`);
      const updates = response.data.zayssite;

      // Функция для парсинга даты из строки
      const parseDate = (dateString) => parse(dateString, 'd MMMM yyyy г.', new Date(), { locale: ru });

      // Сортировка по дате от свежих к старым
      const sortedUpdates = updates.sort((a, b) => parseDate(b.data) - parseDate(a.data));

      setServiceUpdates(sortedUpdates);
    } catch (error) {
      console.error('Error fetching service updates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains('modal-form')) {
      setShowModal(false);
      setUpdateDescription('');
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setUpdateDescription('');
  };

  const autoResize = (elem) => {
    elem.style.height = 'auto';
    elem.style.height = (elem.scrollHeight - 4) + 'px';
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="p-2 w-full mx-auto bg-white rounded-lg shadow-lg">
        {/* Header Section */}
        <div className="flex items-center justify-between bg-gray-900 text-gray-50 p-4 rounded-lg shadow-md">
        {store.user.role == 'dev' && (
          <button
            className="h-10 w-10 bg-gray-800 hover:bg-gray-700 text-center rounded-full flex items-center justify-center text-2xl font-bold"
            onClick={handleClick}
          >
            +
          </button>
        )}
          <h1 className="flex w-full flex-1 text-center text-lg font-semibold ml-4">История обновлений</h1>
        </div>

        {/* Loading Spinner */}
        {isLoading ? (
          <div className="flex justify-center items-center my-8">
            <ReactLoading
              type="spinningBubbles"
              color="#103d77"
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div className="mt-6 space-y-4">
            {Array.isArray(serviceUpdates) && serviceUpdates.map(update => (
              <div key={update.id} className="bg-gray-50 border border-gray-200 rounded-lg shadow-sm p-4 transition-transform transform hover:scale-105">
                <div className="flex justify-between text-sm text-gray-600 mb-2">
                  <span>Сборка: {update.version}</span>
                  <span>{update.data}</span>
                </div>
                <h5 className="text-lg font-bold text-gray-900">Что нового:</h5>
                <TextareaAutosize
                  className="w-full mt-2 p-2 border border-gray-300 rounded-lg resize-none"
                  value={update.opisanie}
                  disabled
                  rows={3}
                />
              </div>
            ))}
          </div>
        )}

        {/* Modal for Adding Updates */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" onClick={handleModalClick}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md" onClick={e => e.stopPropagation()}>
              <h2 className="text-xl font-semibold mb-4">Добавить информацию об обновлении</h2>
              <form onSubmit={e => { e.preventDefault(); handleSave(); }}>
                <div className="mb-4">
                  <label htmlFor="version" className="block text-sm font-medium text-gray-700">Версия:</label>
                  <input
                    type="text"
                    id="version"
                    value={require('../../../../../../package.json').version}
                    disabled
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-lg"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="opisanie" className="block text-sm font-medium text-gray-700">Описание:</label>
                  <TextareaAutosize
                    id="opisanie"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-lg resize-none"
                    placeholder="Введите описание обновления"
                    value={updateDescription}
                    onChange={e => setUpdateDescription(e.target.value)}
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Сохранить</button>
                  <button type="button" onClick={handleCancel} className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300">Отмена</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default observer(ServiceUpdatesBlog);
