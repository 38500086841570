import React, { useContext, useState, useEffect } from 'react';
import './Header.css';
import Logo from '../../../../icon.svg';
import { FaRegUser, Circle } from "react-icons/fa";
import { Context } from "../../../../index";
import axios from 'axios';
import { API_URL } from '../../../../http';
import { useNavigate } from 'react-router-dom';

function Header({ toggleSidebar }) {
    const navigate = useNavigate();
    const { store } = useContext(Context);

    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const [familia, setFamilia] = useState('-------');
    const [imya, setImya] = useState('-------');

    const toggleUserMenu = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const userMenu = document.getElementById('dropdown-user');
            if (userMenu && !userMenu.contains(event.target)) {
                setIsUserMenuOpen(false);
            }
        };

        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${API_URL}/loadprofileById/${store.user.id}`);
                setFamilia(response.data.familia);
                setImya(response.data.imya);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [store.user.id]);

    const handleLogout = () => {
        store.logout();
    };

    return (
        <nav className="fixed top-0 z-50 w-full bg-slate-800">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start rtl:justify-end">
                        <button onClick={toggleSidebar} aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                            </svg>
                        </button>
                        <span className="flex ms-2 md:me-24">
                            <img src={Logo} className="h-8 me-3 border-black shadow-xl bg-white rounded-[50%]" alt="logo" />
                            <span className="self-center text-xl font-semibold sm:text-2xl whitespace-wrap text-slate-50">Личный кабинет "Принт-Сервис"</span>
                        </span>
                    </div>
                    <div className="flex items-center">
                        <div className="flex items-center ms-3">
                            <div>
                                <button onClick={toggleUserMenu} type="button" className="flex text-sm focus:ring-4 focus:ring-gray-300 hover:ring-gray-300 h-8 me-3 border-black shadow-xl bg-white rounded-[50%]" aria-expanded={isUserMenuOpen}>
                                    <FaRegUser Circle className='flex relative w-full h-full' />
                                </button>
                            </div>
                            <div className={`absolute right-10 top-10 z-50 ${isUserMenuOpen ? '' : 'hidden'} my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600`} id="dropdown-user">
                                <div className="px-4 py-3" role="none">
                                    <p className="text-sm text-gray-900 dark:text-white" role="none">
                                        {familia} {imya}
                                    </p>
                                </div>
                                <ul className="py-1" role="none">
                                    <li>
                                        <button onClick={() => navigate("profile")} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Профиль</button>
                                    </li>
                                    <li>
                                        <button onClick={handleLogout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Выход</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </nav >
    );
}

export default Header;